import React, { FC } from 'react';

import Template from '~/components/Template';
import menu from '~/constants/menu/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const TemplateAdmin: FC<UserProps> = ({ children, user }) => (
  <Template kind="admin" menu={menu} showNavigation user={user}>
    {children}
  </Template>
);
export default requireUser(TemplateAdmin);
